import React, { useState } from 'react';
import style from './style.css';
import WoodBg from '../../../images/Seamless_WoodBG.svg';
import Guidebook from '../../../images/Guidebook_Open.svg';
import NotificationIcon from 'cccisd-icons/notification2';
import CloseIcon from 'cccisd-icons/cross';
import Badge from './Badge';
import Resources from './Resources';
import BackToDashboard from './BackToDashboard';

const ResourceHub = props => {
    const resourceCategories = ['medical', 'exercise', 'lifehack', 'chef', 'tranquil', 'wrapup'];
    const {
        toggleIsShowingResources,
        unviewedResourceList,
        completedModuleList,
        onViewResource,
    } = props;

    const [initialResource, setInitialResource] = useState(false);
    const [newResourcesCount, setNewResourcesCount] = useState(unviewedResourceList.length);

    if (initialResource !== false) {
        return (
            <Resources
                unviewedResourceList={unviewedResourceList}
                completedModuleList={completedModuleList}
                initialResource={initialResource}
                toggleIsShowingResources={toggleIsShowingResources}
                onViewResource={onViewResource}
                resourceCategories={resourceCategories}
            />
        );
    }

    return (
        <div style={{ backgroundImage: `url(${WoodBg})` }} className={style.pageWrapper}>
            <BackToDashboard onClick={toggleIsShowingResources} isMainHubPage />
            {newResourcesCount > 0 && (
                <div className={style.notificationMessage}>
                    <p>
                        <NotificationIcon />
                        &nbsp;&nbsp;You have {newResourcesCount} unlocked resource
                        {newResourcesCount !== 1 && 's'}!
                    </p>
                    <button
                        className="btn btn-default"
                        type="button"
                        onClick={() => setNewResourcesCount(0)}
                    >
                        <CloseIcon />
                    </button>
                </div>
            )}

            <img src={Guidebook} className={style.guidebook} alt="guidebook" />
            <div className={style.badgeColumn}>
                {resourceCategories.map(category => {
                    let notificationCount = props.unviewedResourceList.filter(resource =>
                        resource.tagList.find(tag => tag.handle === category)
                    ).length;

                    return (
                        <Badge
                            key={category}
                            type={category}
                            notificationCount={notificationCount}
                            onClick={() => setInitialResource(category)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ResourceHub;

import React from 'react';
import style from './style.css';
import ArrowIcon from 'cccisd-icons/arrow-left8';

const BackToDashboard = ({ onClick, isMainHubPage }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={`${style.backToDashboard} ${isMainHubPage && style.isMainHubPage}`}
        >
            <ArrowIcon />
            &nbsp;&nbsp;Dashboard
        </button>
    );
};

export default BackToDashboard;

import React, { useContext } from 'react';
import useWithModulesMediator from 'cccisd-laravel-assignment/dist/components/navigations/withModules/Mediator/useWithModulesMediator.js';
import { Player, DefaultPlayerSettingsContext } from 'cccisd-laravel-flow';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import style from './style.css';

import ArrowIcon from 'cccisd-icons/arrow-right2';
import CloseIcon from 'cccisd-icons/cross2';
import LockIcon from 'cccisd-icons/svg/lock4';
import NotificationIcon from 'cccisd-icons/notification2';
import PlayIcon from 'cccisd-icons/play4';
import ReplayIcon from 'cccisd-icons/spinner11';

import WoodBg from '../../../images/dashboard/WoodBg.png';
import WoodBg2 from '../../../images/dashboard/WoodBg2.png';
import Rope from '../../../images/dashboard/Rope.png';
import CompletedBadge from '../../../images/dashboard/CompletedBadge.png';
import Guidebook from '../../../images/dashboard/GuidebookClosed.png';
import Placeholder from '../../../images/dashboard/Placeholder.png';
import { Html } from 'cccisd-wysiwyg';
import TopoBg from '../../../images/pageStyles/TopoMap.svg';
import MtnBg from '../../../images/pageStyles/MtnGraphic.svg';
import BlueBg from '../../../images/pageStyles/BlueBG.svg';
import { CourseLayoutContext } from '../../layouts/CourseMainLayout';

const initialSettings = {
    mustTakeInOrder: true,
    dashboardBackground: 'none',
    dashboardSolidColor: '#ffffff',
    lessonBackground: 'none',
    lessonSolidColor: '#ffffff',
};

const backgroundImageMapper = {
    topo: TopoBg,
    mtn: MtnBg,
    blue: BlueBg,
};

// const translate = {
//     en: {
//         moduleText: 'MODULE',
//         overview: 'Overview',
//     },
//     es: {
//         moduleText: 'MÓDULO',
//         overview: 'El Resumen',
//     },
// };

const Mediator = props => {
    let { mediatorProps, isPreviewMode, isNetworkError } = props;
    const { setIsPlayingLesson } = useContext(CourseLayoutContext);
    mediatorProps = mediatorProps || {};
    // if it is a Quest Preview or if this assignment is shared via an anonymous deployment
    // we need the dashboard to still render without errors, though no resource functionality
    if (isPreviewMode || !mediatorProps || !mediatorProps.unviewedResourceList) {
        mediatorProps.unviewedResourceList = [];
        mediatorProps.onClickResources = () =>
            notification({
                message: 'Guidebook not available in Preview Mode',
                type: 'info',
            });
    }

    // const language = Object.keys(translate).includes(props.language) ? props.language : 'en';
    const settings = {
        ...initialSettings,
        ...mediatorProps,
    };

    const { mustTakeInOrder } = settings;
    const { closeFlowPlayer, currentFlowProps, modules, isLoading } = useWithModulesMediator({
        ...props,
        mustTakeInOrder,
    });
    const currentModule = modules.find(m => m.isActive);

    function getBackgroundStyle(view) {
        let backgroundSelection = settings[`${view}Background`];

        let backgroundStyles;
        if (backgroundSelection === 'upload' && settings[`${view}BackgroundUrl`]) {
            let bgUrl = settings[`${view}BackgroundUrl`];
            backgroundStyles = `url("${bgUrl}") center center/cover no-repeat `;
        } else if (backgroundSelection === 'color') {
            let backgroundColor = settings[`${view}SolidColor`];
            backgroundStyles = `${backgroundColor} no-repeat center center`;
        } else if (backgroundSelection in backgroundImageMapper) {
            backgroundStyles = `url("${backgroundImageMapper[backgroundSelection]}") center center/cover no-repeat `;
        } else {
            backgroundStyles = 'white no-repeat center center';
        }

        return { background: backgroundStyles };
    }

    function renderPlayerView() {
        if (!currentFlowProps) {
            return null;
        }

        const customCurrentFlowProps = {
            ...currentFlowProps,
            onComplete: () => {
                currentFlowProps.onComplete();
                setIsPlayingLesson(false);
            },
        };

        return (
            <div
                style={getBackgroundStyle('lesson')}
                className={`${style.mediatorBackground} ${style.playerView}`}
                data-cccisd-survey-area
            >
                <div className="container">
                    <div className={style.playerNav}>
                        <span className={style.breadcrumbs}>
                            {currentModule.title}&nbsp;&nbsp;
                            <ArrowIcon />
                            &nbsp;&nbsp;
                            <span className={style.flowTitle}>{customCurrentFlowProps.title}</span>
                        </span>
                        <button
                            onClick={() => {
                                setIsPlayingLesson(false);
                                closeFlowPlayer();
                            }}
                            className={style.closeIcon}
                            type="button"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div className={style.flow}>
                        <Player
                            key={customCurrentFlowProps.handle}
                            {...customCurrentFlowProps}
                            isNetworkError={isNetworkError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    function renderModuleView() {
        if (currentFlowProps || !currentModule) {
            return null;
        }

        return (
            <div style={getBackgroundStyle('dashboard')} className={style.mediatorBackground}>
                <div className="container">
                    {renderResourcesMessage({ isMobile: true })}
                    <div className="row">
                        <div className="col-xs-12 col-lg-8">{renderModuleProgress()}</div>
                        <div className="col-xs-12 col-lg-4">{renderResourcesMessage({ isMobile: false })}</div>
                    </div>
                </div>
            </div>
        );
    }

    function renderResourcesMessage({ isMobile }) {
        let isNewResources = mediatorProps.unviewedResourceList.length > 0;

        return (
            <div
                className={`${style.resourceMessage} ${isMobile && style.mobile}`}
                style={{ backgroundImage: `url(${WoodBg})` }}
            >
                <img src={Guidebook} alt="Guidebook" />
                <button
                    type="button"
                    className={`btn ${style.dashboardButton} ${style.resourceButton}`}
                    onClick={mediatorProps.onClickResources}
                >
                    Go to Guidebook
                </button>
                {isNewResources && (
                    <div className={style.newResourceMessage}>
                        <p>
                            <NotificationIcon />
                            &nbsp;&nbsp;New unlocked resources
                        </p>
                        <div className={style.triangleDown} />
                    </div>
                )}
            </div>
        );
    }

    function renderModuleProgress() {
        return (
            <>
                <div className={style.ropeContainer}>
                    <span style={{ backgroundImage: `url(${Rope})` }} className={style.rope} />
                    <span style={{ backgroundImage: `url(${Rope})` }} className={style.rope} />
                </div>
                {modules.map(module => (
                    <div
                        style={{ backgroundImage: `url(${WoodBg2})` }}
                        key={module.id}
                        className={style.moduleProgressBg}
                    >
                        <div className={style.moduleProgressContainer}>
                            {module.isComplete && (
                                <img className={style.completedBadge} src={CompletedBadge} alt="completed" />
                            )}
                            <div className={style.moduleDetails}>
                                <span>
                                    <h5>MODULE {module.id}</h5>
                                    <h4>{module.title}</h4>
                                    <p>
                                        <Html content={module.overview} />
                                    </p>
                                </span>
                                <span
                                    style={{
                                        backgroundImage: `url(${module.imageUrl || Placeholder})`,
                                    }}
                                    className={style.moduleImage}
                                />
                            </div>
                            {module.lessons.map((lesson, lessonIndex) =>
                                renderLessonCard(lesson, module.lessons.length > 1, lessonIndex, module)
                            )}
                        </div>
                    </div>
                ))}
            </>
        );
    }

    function renderLessonCard(lesson, isShowLessonLabel, lessonIndex, module) {
        // remove this line to remove borders on modules with just one lesson
        isShowLessonLabel = true;
        // Was up in the air which way it looked better

        let buttonText = 'Start';
        let buttonIcon = <PlayIcon />;
        let cssClass = 'notStarted';

        if (!lesson.isAllowed) {
            buttonIcon = <LockIcon />;
            cssClass = 'locked';
        } else if (lesson.isResume) {
            buttonText = 'Resume';
            cssClass = 'inProgress';
        } else if (lesson.isCompleted) {
            buttonText = 'Replay';
            buttonIcon = <ReplayIcon />;
            cssClass = 'complete';
        }

        return (
            <div className={style.lessonContainer} key={lessonIndex}>
                <div className={`${style.lessonStatus} ${style[cssClass]}`}>
                    <div className={style.outer}>
                        {cssClass === 'inProgress' && <div className={style.partial} />}
                        <div className={style.inner}>{lessonIndex + 1}</div>
                    </div>
                    <div className={style.statusBar} />
                </div>
                <div
                    className={`${style.lessonCard} ${style[cssClass]} ${isShowLessonLabel && style.bordered}`}
                    key={lesson.flowId}
                >
                    {isShowLessonLabel && <h6>{lesson.title}</h6>}
                    <button
                        type="button"
                        className={`btn ${style.dashboardButton}`}
                        onClick={() => {
                            if (!lesson.isAllowed) {
                                return;
                            }

                            setIsPlayingLesson(true);
                            if (module.lessons.length > 0 && module.isAllowed) {
                                module.setActive();
                            }
                            lesson.setCurrent();
                        }}
                        disabled={!lesson.isAllowed}
                    >
                        <span className={style.buttonIcon}>{buttonIcon}</span>&nbsp;&nbsp;
                        {buttonText}
                    </button>
                </div>
            </div>
        );
    }

    return (
        <DefaultPlayerSettingsContext.Provider
            value={{
                background: 'solidColor',
                backgroundColor: '#fff',
            }}
        >
            <Loader loading={isLoading} removeChildren={!isNetworkError}>
                {renderPlayerView()}
                {renderModuleView()}
            </Loader>
        </DefaultPlayerSettingsContext.Provider>
    );
};

export default Mediator;

import React from 'react';
import style from './style.css';
import MedicalBadge from '../../../images/Badge_MedicalTips.svg';
import ExerciseBadge from '../../../images/Badge_ExerciseExploration.svg';
import LifehackBadge from '../../../images/Badge_LifehackLessons.svg';
import ChefBadge from '../../../images/Badge_ChefsKitchen.svg';
import TranquilBadge from '../../../images/Badge_TranquilTeachings.svg';
import WrapupBadge from '../../../images/Badge_ModuleWrapup.svg';

const imageMapper = {
    medical: MedicalBadge,
    exercise: ExerciseBadge,
    lifehack: LifehackBadge,
    chef: ChefBadge,
    tranquil: TranquilBadge,
    wrapup: WrapupBadge,
};

const Badge = ({ type, notificationCount, onClick }) => {
    return (
        <div className={style.badge} onClick={onClick}>
            {notificationCount > 0 && (
                <span className={style.notificationBubble}>{notificationCount}</span>
            )}
            <img src={imageMapper[type]} alt={`badge for ${type}`} />
        </div>
    );
};

export default Badge;

import React from 'react';
import style from './style.css';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import AssignmentCompletionMessage from '../../components/AssignmentCompletionMessage';
import ResourceHub from '../ResourceHub';

import queryAssignmentInfo from './queryAssignmentInfo.graphql';
import queryModuleProgress from './queryModuleProgress.graphql';
import queryResources from './queryResources.graphql';

const deploymentHandle = 'main';
const resourceTagAllModules = 'all';
const Fortress = window.cccisd && window.cccisd.fortress;

export default class SiteUserDashboard extends React.Component {
    actingPawnId = Fortress.user.acting.id;
    deploymentId = null;

    state = {
        isLoading: true,
        errorMessage: '',
        completedModuleList: [],
        unviewedResourceList: [],
        isShowingResources: false,
        isShowingAssignmentCompletionMessage: false,
    };

    async componentDidMount() {
        const responseAssignmentInfo = await client.query({
            query: queryAssignmentInfo,
            variables: { deploymentHandle },
            fetchPolicy: 'network-only',
        });

        try {
            this.deploymentId = responseAssignmentInfo.data.flows.deployment.deploymentId;
            if (!this.deploymentId) {
                throw new Error('Could not find Deployment with the handle "main".');
            }
        } catch (e) {
            console.error(e);
            return this.setState({
                isLoading: false,
                errorMessage: 'Could not load dashboard at this time :(',
            });
        }

        await this.setCompletedModuleList();
        await this.setUnviewedResources();
    }

    setCompletedModuleList = async () => {
        const responseModuleProgress = await client.query({
            query: queryModuleProgress,
            variables: {
                deploymentId: this.deploymentId,
                actingPawnId: this.actingPawnId,
            },
            fetchPolicy: 'network-only',
        });

        let { moduleProgressStatus } = responseModuleProgress.data.roles.learner;

        let completedModuleList = Object.keys(moduleProgressStatus)
            .map(moduleKey => {
                const moduleKeySplit = moduleKey.split('_');
                if (moduleKeySplit[2]) {
                    return null;
                }
                return moduleProgressStatus[moduleKey] === 2 && moduleKeySplit[1];
            })
            .filter(m => m); // remove nulls
        return this.setState({
            completedModuleList: [resourceTagAllModules, ...completedModuleList],
        });
    };

    setUnviewedResources = () => {
        this.setState({ isLoading: true }, async () => {
            let tags = this.state.completedModuleList;

            let responseResources = await client.query({
                query: queryResources,
                variables: {
                    actingPawnId: this.actingPawnId,
                    tags,
                },
                fetchPolicy: 'network-only',
            });

            let unviewedResourceList = responseResources.data.resources.resourceList.filter(
                resource => resource.alreadyViewed === 'false'
            );

            this.setState({ isLoading: false, unviewedResourceList });
        });
    };

    onModuleComplete = completionInfo => {
        let tagName = 'module' + completionInfo.moduleId;
        if (!this.state.completedModuleList.includes(tagName)) {
            this.setState({ completedModuleList: [...this.state.completedModuleList, tagName] }, async () => {
                await this.setUnviewedResources();
            });
        }
    };

    toggleIsShowingResources = () => {
        this.setState({ isShowingResources: !this.state.isShowingResources });
    };

    onViewResource = resourceId => {
        this.setState({
            unviewedResourceList: this.state.unviewedResourceList.filter(
                resource => resource.resourceId !== resourceId
            ),
        });
    };

    render() {
        if (this.state.errorMessage) {
            return (
                <div className={style.errorMessage}>
                    <p>{this.state.errorMessage}</p>
                    <span>
                        Please contact 3C Institute for support at{' '}
                        <a href="mailto: support@3cisd.com">support@3cisd.com</a> or via phone at{' '}
                        <a href="tel:984-316-0406">984-316-0406</a>.
                    </span>
                </div>
            );
        }

        if (this.state.isShowingAssignmentCompletionMessage) {
            return (
                <AssignmentCompletionMessage
                    returnToDashboard={() => this.setState({ isShowingAssignmentCompletionMessage: false })}
                />
            );
        }

        if (this.state.isShowingResources) {
            return (
                <ResourceHub
                    unviewedResourceList={this.state.unviewedResourceList}
                    toggleIsShowingResources={this.toggleIsShowingResources}
                    onViewResource={this.onViewResource}
                    completedModuleList={this.state.completedModuleList}
                />
            );
        }

        return (
            <Loader loading={this.state.isLoading}>
                <DeploymentPlayer
                    deploymentHandle={deploymentHandle}
                    pawnId={this.actingPawnId}
                    pawnHash={Fortress.user.acting.random_hash}
                    onModuleComplete={this.onModuleComplete}
                    mediatorProps={{
                        unviewedResourceList: this.state.unviewedResourceList,
                        onClickResources: this.toggleIsShowingResources,
                    }}
                    onComplete={() => this.setState({ isShowingAssignmentCompletionMessage: true })}
                />
            </Loader>
        );
    }
}

import React, { createContext, useState } from 'react';
import style from './style.css';
import NavBar from '../../components/NavBar';
import Footer from 'cccisd-footer';

export const CourseLayoutContext = createContext({
    isPlayingLesson: false,
    setIsPlayingLesson: () => {},
});

const CourseMainLayout = props => {
    const [isPlayingLesson, setIsPlayingLesson] = useState(false);

    return (
        <CourseLayoutContext.Provider value={{ setIsPlayingLesson }}>
            <div className={style.pageWrapper}>
                {!isPlayingLesson && <NavBar className={props.className} />}
                <div className={style.body}>{props.children}</div>
                {!isPlayingLesson && (
                    <div className={style.footer}>
                        <Footer />
                    </div>
                )}
            </div>
        </CourseLayoutContext.Provider>
    );
};
export default CourseMainLayout;

import Topo from './Topo.js';
import Mtn from './Mtn.js';
import Blue from './Blue.js';
import White from './White.js';

export default [
    {
        handle: 'white',
        label: 'CKD',
        component: White,
        thumbnail: White.thumbnail,
        flowPlayerSettings: {
            background: 'solidColor',
            backgroundColor: '#ffffff',
        },
    },
    {
        handle: 'topo',
        label: 'Topo',
        component: Topo,
        thumbnail: Topo.thumbnail,
        flowPlayerSettings: {
            background: 'solidColor',
            backgroundColor: '#fff',
        },
    },
    {
        handle: 'mtn',
        label: 'Mountain',
        component: Mtn,
        thumbnail: Mtn.thumbnail,
        flowPlayerSettings: {
            background: 'solidColor',
            backgroundColor: '#fff',
        },
    },
    {
        handle: 'blueBg',
        label: 'Blue',
        component: Blue,
        thumbnail: Blue.thumbnail,
        flowPlayerSettings: {
            background: 'solidColor',
            backgroundColor: '#fff',
        },
    },
];

import thumbnail from 'cccisd-laravel-assignment/dist/components/navigations/withModules/withModules.png';
import Mediator from './Mediator.js';
import Settings from './Settings.js';

export default {
    handle: 'moduleCKD',
    label: 'CKD',
    thumbnail,
    settingsComponent: Settings,
    mediatorComponent: Mediator,
};

import React, { useState } from 'react';
import Modal from 'cccisd-modal';
import { addEvent } from 'cccisd-laravel-eventlog';
import ExpandArrowIcon from 'cccisd-icons/arrow-down2';
import CollapseArrowIcon from 'cccisd-icons/arrow-up2';
import { ResourceCenter } from 'cccisd-laravel-resources';
import style from './style.css';

import MedicalBannerCollapsed from '../../../../images/collapsed/Banner_MedicalTips.svg';
import MedicalBannerExpanded from '../../../../images/expanded/Banner_MedicalTips.svg';
import MedicalBannerRepeatable from '../../../../images/expanded/Banner_Medical_repeatable.svg';
import ExerciseBannerCollapsed from '../../../../images/collapsed/Banner_ExerciseExploration.svg';
import ExerciseBannerExpanded from '../../../../images/expanded/Banner_ExerciseExploration.svg';
import ExerciseBannerRepeatable from '../../../../images/expanded/Banner_Exercise_repeatable.svg';
import LifehackBannerCollapsed from '../../../../images/collapsed/Banner_LifehackLessons.svg';
import LifehackBannerExpanded from '../../../../images/expanded/Banner_LifehackLessons.svg';
import LifehackBannerRepeatable from '../../../../images/expanded/Banner_Lifehack_repeatable.svg';
import ChefBannerCollapsed from '../../../../images/collapsed/Banner_ChefsKitchen.svg';
import ChefBannerExpanded from '../../../../images/expanded/Banner_ChefsKitchen.svg';
import ChefBannerRepeatable from '../../../../images/expanded/Banner_Chef_repeatable.svg';
import TranquilBannerCollapsed from '../../../../images/collapsed/Banner_TranquilTeachings.svg';
import TranquilBannerExpanded from '../../../../images/expanded/Banner_TranquilTeachings.svg';
import TranquilBannerRepeatable from '../../../../images/expanded/Banner_Tranquil_repeatable.svg';
import WrapupBannerCollapsed from '../../../../images/collapsed/Banner_ModuleWrapup.svg';
import WrapupBannerExpanded from '../../../../images/expanded/Banner_ModuleWrapup.svg';
import WrapupBannerRepeatable from '../../../../images/expanded/Banner_ModuleWrapup_repeatable.svg';

const imageMapper = {
    medical: {
        collapsed: MedicalBannerCollapsed,
        expanded: MedicalBannerExpanded,
        collapsedRepeatable: '#97D0E1',
        expandedRepeatable: MedicalBannerRepeatable,
    },
    exercise: {
        collapsed: ExerciseBannerCollapsed,
        expanded: ExerciseBannerExpanded,
        collapsedRepeatable: '#D64D3F',
        expandedRepeatable: ExerciseBannerRepeatable,
    },
    lifehack: {
        collapsed: LifehackBannerCollapsed,
        expanded: LifehackBannerExpanded,
        collapsedRepeatable: '#51631B',
        expandedRepeatable: LifehackBannerRepeatable,
    },
    chef: {
        collapsed: ChefBannerCollapsed,
        expanded: ChefBannerExpanded,
        collapsedRepeatable: '#6E4C89',
        expandedRepeatable: ChefBannerRepeatable,
    },
    tranquil: {
        collapsed: TranquilBannerCollapsed,
        expanded: TranquilBannerExpanded,
        collapsedRepeatable: '#212C3D',
        expandedRepeatable: TranquilBannerRepeatable,
    },
    wrapup: {
        collapsed: WrapupBannerCollapsed,
        expanded: WrapupBannerExpanded,
        collapsedRepeatable: '#6D3E64',
        expandedRepeatable: WrapupBannerRepeatable,
    },
};

const imageFileExt = ['.png', '.jpg', '.jpeg'];

const Banner = ({
    type,
    isExpandedInitially,
    unviewedResourceList,
    completedModuleList,
    onViewResource,
}) => {
    const [isExpanded, setIsExpanded] = useState(isExpandedInitially);

    const getExternalLink = (resource) => {
        if (resource.type === 'document') {
            return resource.url;
        }
        if (resource.type === 'link') {
            return JSON.parse(resource.published_metadata).link;
        }
    };

    const addViewResourceEvent = (resource) => {
        addEvent(
            {
                eventType: 'viewResource',
                targetId: resource.id,
                targetType: 'resource',
            },
            { saveImmediately: true }
        );
        onViewResource(resource.id);
    };

    const renderModal = (resource, trigger) => {
        let isVideo = resource.type === 'video';
        return (
            <Modal size="large" trigger={trigger} title={resource.published_label}>
                {isVideo ? (
                    <div className={style.imageModalContent}>
                        {resource.published_description && (
                            <p
                                dangerouslySetInnerHTML={{ __html: resource.published_description }}
                            />
                        )}
                        <div
                            className={style.videoModalContent}
                            dangerouslySetInnerHTML={{
                                __html: JSON.parse(resource.published_metadata).embed,
                            }}
                        />
                    </div>
                ) : (
                    <div className={style.imageModalContent}>
                        {resource.published_description && (
                            <p
                                dangerouslySetInnerHTML={{ __html: resource.published_description }}
                            />
                        )}
                        <img src={resource.url} alt={resource.published_label} />
                    </div>
                )}
            </Modal>
        );
    };

    const renderLabel = (resource, isOpenInModal) => {
        let label = (
            <h3>
                <a
                    onClick={() => addViewResourceEvent(resource)}
                    onContextMenu={() => addViewResourceEvent(resource)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getExternalLink(resource)}
                >
                    {resource.published_label}
                </a>
            </h3>
        );

        return isOpenInModal ? renderModal(resource, label) : label;
    };

    const renderThumbnail = (resource, isOpenInModal) => {
        const thumbnailUrl = resource.large;
        const backgroundSize = JSON.parse(resource.published_metadata || '{}').imageStyle;

        const thumbnail = (
            <a
                onClick={() => addViewResourceEvent(resource)}
                onContextMenu={() => addViewResourceEvent(resource)}
                href={getExternalLink(resource)}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div
                    style={{
                        backgroundImage: `url("${thumbnailUrl}")`,
                        backgroundPosition: backgroundSize === 'icon' ? '2em 100%' : 'center',
                        backgroundSize: backgroundSize === 'icon' ? '75px 75px' : backgroundSize,
                    }}
                    className={style.thumbnail}
                >
                    <img
                        style={{ display: 'none' }}
                        src={thumbnailUrl}
                        alt={resource.published_label}
                    />
                </div>
            </a>
        );

        return isOpenInModal ? renderModal(resource, thumbnail) : thumbnail;
    };

    const renderResourceTiles = ({ data }) => {
        // Only show resources for the completed modules
        data = data.filter((resource) => {
            let resourceTags = Object.values(resource.tags);
            return resourceTags.some((tag) => completedModuleList.includes(tag));
        });

        return (
            <div className="container">
                <div className="row">
                    {data.length > 0 ? (
                        data.map((resource) => {
                            const isOpenInModal =
                                resource.type === 'video' ||
                                (resource.type === 'document' &&
                                    imageFileExt.includes(
                                        resource.filename
                                            .slice(resource.filename.lastIndexOf('.'))
                                            .toLowerCase()
                                    ));

                            const isNew = unviewedResourceList.some(
                                (unviewedResource) => unviewedResource.resourceId === resource.id
                            );

                            return (
                                <div
                                    className={`col-xs-6 col-md-4 ${style.resourceTileContainer}`}
                                    key={resource.id}
                                >
                                    <div>
                                        {isNew && <p className={style.newResource}>New!</p>}
                                        {renderThumbnail(resource, isOpenInModal)}
                                        <div className={style.detailsContainer}>
                                            {renderLabel(resource, isOpenInModal)}
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: resource.published_description,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p className={style.noResourcesMessage}>
                            There are no resources unlocked yet! Complete the course modules to
                            unlock resources.
                        </p>
                    )}
                </div>
            </div>
        );
    };

    let newResourceCount = unviewedResourceList.filter((unviewedResource) =>
        unviewedResource.tagList.find((tag) => tag.handle === type)
    ).length;

    return (
        <>
            <div className={`${style.bannerContainer} ${isExpanded && style.isExpanded}`}>
                <span
                    style={{
                        backgroundColor: imageMapper[type].collapsedRepeatable,
                        backgroundImage:
                            isExpanded && `url(${imageMapper[type].expandedRepeatable})`,
                    }}
                />
                <span
                    style={{
                        backgroundImage: `url(${
                            isExpanded ? imageMapper[type].expanded : imageMapper[type].collapsed
                        })`,
                    }}
                />
                <span
                    style={{
                        backgroundColor: imageMapper[type].collapsedRepeatable,
                        backgroundImage:
                            isExpanded && `url(${imageMapper[type].expandedRepeatable})`,
                    }}
                />
                <button
                    type="button"
                    className={`btn btn-default ${style.expandButton}`}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? (
                        <span>
                            <CollapseArrowIcon />
                        </span>
                    ) : (
                        <ExpandArrowIcon />
                    )}
                </button>
                {newResourceCount > 0 && (
                    <span className={style.notificationBubble}>{newResourceCount}</span>
                )}
            </div>
            {isExpanded && (
                <ResourceCenter handle={type} renderResourceTiles={renderResourceTiles} />
            )}
        </>
    );
};

export default Banner;

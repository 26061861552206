import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../../components/NavBar';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import Footer from 'cccisd-footer';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export const laravelHeaderMargin = '60px';

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
        showFooter: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: true,
        showFooter: true,
    };

    render() {
        const { children, className, showFooter, showSecondaryNav } = this.props;

        return (
            <div className={style.wrapper}>
                <NavBar className={className} marginBottom="11px" />
                {showSecondaryNav &&
                    Fortress.auth() &&
                    Fortress.user.acting.role.handle !== 'learner' && (
                        <div className={style.secondaryNav}>
                            <SecondaryNav
                                className={className}
                                navs={AppDefs.navs}
                                routes={AppDefs.routes}
                            />
                        </div>
                    )}
                <div className={style.body}>
                    <div className={className}>{children}</div>
                </div>

                {showFooter && (
                    <div className={style.footer}>
                        <Footer className={className} />
                    </div>
                )}
            </div>
        );
    }
}

import React from 'react';
import { Switch } from 'react-router-dom';
import defaultProps from 'recompose/defaultProps.js';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import { AdminRoutes, Dashboard as AppdefDashboard, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import SiteUserDashboard from './pages/SiteUserDashboard'; // Include it into main bundle

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';
import CourseMainLayout from './layouts/CourseMainLayout';

// Bind MainLayoutFluid by default
const MainLayoutNoSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: false,
})(MainLayout);
const MainLayoutNoSecondaryNavNoFluid = defaultProps({
    className: 'container',
    showSecondaryNav: false,
})(MainLayout);
const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const AppRoute = defaultProps({ layout: MainLayoutFluid })(Route);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const match = {
    AppdefDashboard,
    AppdefManage,
    Welcome,
    SiteUserDashboard,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutNoSecondaryNav,
    MainLayoutNoSecondaryNavNoFluid,
    QuestLayout,
    CourseMainLayout,
    Null: props => props.children,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? layoutMatch[route.layout] : MainLayoutFluid}
                />
            );
        })}

        <AppRoute path="/" component={Welcome} exact layout={MainLayoutNoSecondaryNavNoFluid} />
        <AppRoute path="/welcome" component={Welcome} exact layout={MainLayoutNoSecondaryNavNoFluid} />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {AdminRoutes(MainLayoutNoSecondaryNav)}

        {/* Laravel packages routes */}
        <AppRoute path="/account" component={Nexus} />
        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} layout={MainLayoutNoSecondaryNavNoFluid} />
    </Switch>
);

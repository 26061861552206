import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import Banner from './Banner';
import BackToDashboard from '../BackToDashboard';

const Resources = props => {
    return (
        <div className={style.bannerPage}>
            <BackToDashboard onClick={props.toggleIsShowingResources} />
            {props.resourceCategories.map(category => (
                <Banner
                    unviewedResourceList={props.unviewedResourceList}
                    onViewResource={props.onViewResource}
                    completedModuleList={props.completedModuleList}
                    key={category}
                    type={category}
                    isExpandedInitially={props.initialResource === category}
                />
            ))}
        </div>
    );
};

Resources.propTypes = {
    completedModuleList: PropTypes.array,
    initialResource: PropTypes.string.isRequired,
    onViewResource: PropTypes.func,
    resourceCategories: PropTypes.array,
    toggleIsShowingResources: PropTypes.func,
    unviewedResourceList: PropTypes.array,
};

export default Resources;

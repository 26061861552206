import React from 'react';
import PropTypes from 'prop-types';
import DefaultHeader from '../AppHeader';
import style from './style.css';
import WhiteBg from '../../../../../images/pageStyles/White.png';

export default class White extends React.Component {
    static propTypes = {
        header: PropTypes.node,
        footer: PropTypes.node,
        showHeader: PropTypes.bool,
        showFooter: PropTypes.bool,
        children: PropTypes.node,
    };

    static thumbnail = WhiteBg;

    render() {
        const { showHeader, showFooter, footer } = this.props;
        const header = this.props.header || <DefaultHeader />;

        return (
            <div className={style.wrapper} style={{ background: 'white' }}>
                {showHeader && <div>{header}</div>}
                <div className={style.whiteBody}>{this.props.children}</div>
                {showFooter && footer && <div>{footer}</div>}
            </div>
        );
    }
}

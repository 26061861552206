import React from 'react';
import PropTypes from 'prop-types';
import DefaultHeader from '../AppHeader';
import style from './style.css';
import MtnBg from '../../../../../images/pageStyles/MtnGraphic.svg';

export default class Topo extends React.Component {
    static propTypes = {
        header: PropTypes.node,
        footer: PropTypes.node,
        showHeader: PropTypes.bool,
        showFooter: PropTypes.bool,
        children: PropTypes.node,
    };

    static thumbnail = MtnBg;

    render() {
        const { showHeader, showFooter, footer } = this.props;
        const header = this.props.header || <DefaultHeader />;

        return (
            <div className={style.wrapper} style={{ backgroundImage: `url(${MtnBg})` }}>
                {showHeader && <div>{header}</div>}
                <div className={style.body}>
                    <div className="container">{this.props.children}</div>
                </div>
                {showFooter && footer && <div>{footer}</div>}
            </div>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import logo from './MyKidneyGuide_Logo.svg';
import style from './style.css';

export default class NavBar extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        marginBottom: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
        marginBottom: '0px',
    };

    render() {
        return (
            <div style={{ marginBottom: this.props.marginBottom }}>
                <Header
                    {...this.props}
                    logo={<img src={logo} alt="My Kidney Guide" className={style.logo} />}
                />
            </div>
        );
    }
}

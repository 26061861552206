import React from 'react';
import style from './style.css';
import Confetti from 'react-confetti';
// import { Confetti } from 'cccisd-laravel-appdefs';
import ArrowIcon from 'cccisd-icons/arrow-left8';

const AssignmentCompletionMessage = ({ returnToDashboard }) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    let numberOfPieces = 100;
    if (screenWidth > 800) {
        numberOfPieces = 200;
    }
    return (
        <div className={style.assignmentCompletionMessage}>
            <Confetti numberOfPieces={numberOfPieces} width={screenWidth} height={screenHeight} />
            <p className={style.congrats}>Congratulations!</p>
            <p>
                Thank you for completing the course. You may continue to review course material and
                resources.
            </p>
            <button
                type="button"
                className={`btn btn-default ${style.button}`}
                onClick={returnToDashboard}
            >
                <ArrowIcon />
                &nbsp;&nbsp;Dashboard
            </button>
        </div>
    );
};

export default AssignmentCompletionMessage;

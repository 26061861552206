import React from 'react';
import style from './style.css';
import { Field, CccisdSelect } from 'cccisd-formik';
import { ImagePicker } from 'cccisd-laravel-resources';
import settingsWrapper from 'cccisd-laravel-assignment/dist/helpers/settingsWrapper';

import TopoBg from '../../../images/pageStyles/TopoMap.svg';
import MtnBg from '../../../images/pageStyles/MtnGraphic.svg';
import BlueBg from '../../../images/pageStyles/BlueBG.svg';

const imageMapper = {
    none: '',
    color: '',
    topo: TopoBg,
    mtn: MtnBg,
    blue: BlueBg,
};

const bgOptions = [
    { value: 'none', label: 'None' },
    { value: 'color', label: 'Solid Color' },
    { value: 'topo', label: 'Topo' },
    { value: 'blue', label: 'Blue' },
    { value: 'mtn', label: 'Mountain' },
    { value: 'upload', label: 'Upload' },
];

export const initialValues = {
    mustTakeInOrder: true,
    dashboardBackground: 'none',
    dashboardSolidColor: '#ffffff',
    dashboardBackgroundUrl: '',
    lessonBackground: 'none',
    lessonSolidColor: '#ffffff',
    lessonBackgroundUrl: '',
};

const Settings = ({ questSettings }) => {
    function renderPreviewThumb(url) {
        return (
            <span
                style={{ backgroundImage: `url("${url}")` }}
                className={style.previewBgSelection}
            />
        );
    }
    function renderPreviewComponent(form, settingKey) {
        const settingsFieldName = `${settingKey}Background`;
        const uploadFieldName = settingsFieldName + 'Url';
        const colorFieldName = settingKey + 'SolidColor';
        const bgTypeOption = questSettings.navigationSettings[settingsFieldName];
        const bgUrl = questSettings.navigationSettings[uploadFieldName];
        const selectedColor = questSettings.navigationSettings[colorFieldName];

        if (bgTypeOption === 'none') {
            return null;
        }
        if (bgTypeOption === 'color') {
            return (
                <>
                    <Field name={colorFieldName} type="color" />
                    <span
                        className={style.previewBgSelection}
                        style={{ backgroundColor: selectedColor }}
                    />
                </>
            );
        }
        if (bgTypeOption === 'upload') {
            return (
                <>
                    <ImagePicker
                        trigger={
                            <button type="button" className="btn btn-default">
                                {bgUrl && bgUrl.startsWith('http')
                                    ? 'Change image'
                                    : 'Upload image'}
                            </button>
                        }
                        onChange={value => form.setFieldValue(uploadFieldName, value.url)}
                    />
                    {bgUrl && bgUrl.startsWith('http') && renderPreviewThumb(bgUrl)}
                </>
            );
        }
        return renderPreviewThumb(imageMapper[bgTypeOption]);
    }

    function renderSelect(formikProps, settingKey) {
        let displayLabel = settingKey[0].toUpperCase() + settingKey.slice(1) + ' background';
        return (
            <>
                <CccisdSelect {...formikProps} options={bgOptions} label={displayLabel} />
                <span className={style.preview}>
                    {renderPreviewComponent(formikProps.form, settingKey)}
                </span>
            </>
        );
    }

    return (
        <>
            <div className={style.settingsField}>
                <Field
                    name="dashboardBackground"
                    render={formikProps => <>{renderSelect(formikProps, 'dashboard')}</>}
                />
            </div>
            <div className={style.settingsField}>
                <Field
                    name="lessonBackground"
                    render={formikProps => <>{renderSelect(formikProps, 'lesson')}</>}
                />
            </div>
        </>
    );
};

export default settingsWrapper({ initialValues })(Settings);
